const PUB_KEY = process.env.REACT_APP_STRIPE_PUBKEY;


const config = {
    development: {
        apiEndpoint: 'http://localhost:8080/api',
        stripePubKey: PUB_KEY || 'pk_test_EzDHBHHQ5x8sW0RGF6RtZhm1'
    },

    test: {
        apiEndpoint: 'http://localhost:9999/api',
        stripePubKey: PUB_KEY || 'pk_test_EzDHBHHQ5x8sW0RGF6RtZhm1'
    },

    stage: {
        apiEndpoint: '/api',
        stripePubKey: PUB_KEY || 'pk_test_EzDHBHHQ5x8sW0RGF6RtZhm1'
    },

    production: {
        apiEndpoint: '/api',
        stripePubKey: PUB_KEY
    }
};


export default config[process.env.NODE_ENV];
