import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Checkout from './components/Checkout';
import InvoiceSearch from './components/InvoiceSearch';
import {Navbar} from './Navbar';
import "./App.css";



const App = () => {
  return (
    <BrowserRouter>
        <Navbar/>
        <StyledEngineProvider injectFirst>
            <Routes>
                <Route exact path='/' element={<InvoiceSearch/>} />
                <Route exact path='/checkout/invoice/:cid/:qid' element={<Checkout type="invoice"/>} />
                <Route exact path='/checkout/quote/:cid/:qid' element={<Checkout type="quote"/>} />
            </Routes>
        </StyledEngineProvider>
    </BrowserRouter>
  );
};


export default App;
