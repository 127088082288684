import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as checkoutUtils from '../services/checkout';
import config from '../config/config';
import html2pdf from 'html2pdf.js/dist/html2pdf.min.js';


import {
    Elements,
    CardElement,
    useStripe,
    useElements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(config.stripePubKey);



const useStyles = (theme) => ({
    card: {
        backgroundColor: 'white',
        padding: '10px 20px 11px',
        borderRadius: '5px',
        border: '1px solid #afafaf',
        boxShadow: '0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)'
    },

    logo: {
        height: 50,
        width: 50,
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 350, md: 250 },
    },

    header: {
        paddingTop: 5,
        textTransform: 'uppercase'
    },

    table: {
        width: '100%',
        textAlign: 'center',
        borderCollapse: 'collapse'
    },

    tableTr: {
        textAlign: 'center',
        borderTop: '1px solid #ddd',
    },

    tableTh: {
        textAlign: 'center',
        backgroundColor: '#555',
        color: '#fff',
        fontWeight: 'normal',
        borderTop: '1px solid #ddd',
    },

    totalRow: {
        borderTop: '1px solid black',
        backgroundColor: '#e3e3e3',
        fontWeight: 'bold'
    }
});



/** Stripe card payment input with 'pay now' button
 *
 * On submission calls input 'onSubmit' callback function
 *
 * function onSubmit(stripe, cardElement)
 *
 * where
 *   - `cardElement` is a Stripe CardElement object
 *   - `stripe` is the Stripe object
 *
 */
function StripePaymentInput(props){
    const [payBtnEnabled, setPayBtnEnabled] = React.useState(false);
    const sx = useStyles(useTheme());
    const elements = useElements();
    const stripe = useStripe();
    const [amount, setAmount] = React.useState('');

    function cardChanged(status){
        setPayBtnEnabled(status.complete);
    }

    async function onSubmit(){
        const cardElement = elements.getElement(CardElement);
        props.onSubmit(stripe, cardElement);
    }

    async function intentUsdAmount(){
        const stripe = await stripePromise;
        const { paymentIntent } = await stripe.retrievePaymentIntent(props.clientSecret);
        return '$' + (paymentIntent.amount/100).toFixed(2);
    }

    React.useEffect(()=>{
        (async ()=>{
            const dollarStr = await intentUsdAmount();
            setAmount(dollarStr);
        })();;
    }, []);


    return (
        <Box spacing={0} sx={{margin: 'auto'}}>
             <Box display="block" sx={{maxWidth: 400, margin: '0 auto'}}>
                 <Stack spacing={2}>
                     <Box style={sx.card}>
                         <CardElement
                            id="card-element"
                            options={{}}
                            onChange={cardChanged}
                        />
                     </Box>
                     <LoadingButton
                        variant='contained'
                        loading={props.processing}
                        onClick={onSubmit}
                        disabled={!payBtnEnabled}>
                         Pay Now [{amount}]
                    </LoadingButton>
                 </Stack>
             </Box>
         </Box>
    );
}

/** Convenience function to render key value pairs in a row
 *
 * Required properties:
 *    label {string}: printed label
 *    value {string}: printed value of label
 */
function RowItem(props){
    return (
        <Grid container sx={{pt: 1}}>
            <Grid item xs={6} sx={{fontWeight: 'bold'}}>
                {props.label}
            </Grid>
            <Grid item xs={6}>
                {props.value}
            </Grid>
        </Grid>
    );
}


function LetterHead(props){
    const sx = useStyles(useTheme());
    return (
        <Grid container sx={{color: '#555', pb: 5}}>
            <Grid item xs={6}>
                <Box
                    component="img"
                    sx={sx.logo}
                    alt="Ensoft logo"
                    src="https://www.ensoftinc.com/img/logo.png"
                />
                <Typography variant="h5" sx={{paddingTop: 0, textTransform: 'uppercase'}}>
                    Ensoft, Inc.
                </Typography>
                <Typography variant="p" sx={{paddingTop: 0, fontSize: 11}}>
                    <Box>
                        Engineering Software
                    </Box>
                    <Box>
                        3003 West Howard Lane, Austin, TX 78728
                    </Box>
                    <Box>
                        Phone: 512-244-6464 | Fax: 512-244-6067
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{display: 'flex', justifyContent: "flex-end"}}>
                    <Typography
                        component="h1"
                        variant="h3"
                        sx={sx.header}>
                        Invoice
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}


function PageHeader(){
    const sx = useStyles(useTheme());

    return (
        <Grid container sx={{color: '#555', pb: 5}}>
            <Grid item xs={6}>
                <Box
                    component="img"
                    sx={sx.logo}
                    alt="Ensoft logo"
                    src="https://www.ensoftinc.com/img/logo.png"
                />
            </Grid>
            <Grid item xs={6}>
                <Box sx={{textAlign: 'right'}}>
                    <Typography variant="h5" sx={{paddingTop: 0, textTransform: 'uppercase'}}>
                        Ensoft, Inc.
                    </Typography>
                    <Typography variant="p" sx={{paddingTop: 0, fontSize: 11}}>
                        <Box>
                            Phone: 512-244-6464
                        </Box>
                        <Box>
                            sales@e<span>ns</span>oftinc.com
                        </Box>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}


/** Invoice page template
 *
 * Required parameters
 *  - invoiceNumber {string}: customer-facing invoice identifier
 *  - customerId {string}: displayed customer identifier
 *  - invoiceNumber {string}: customer-facing invoice identifier
 *  - issueDate {string}: date the invoice was created
 *  - Due Date {string} Date invoice payment is/was due
 *  - status {string} invoice status
 *  - totalAmount {string}: total charges of invoice in USD, without any prefix
 *  - dueAmount {string}: total charges due in USD, without any prefix
 */
function InvoiceContentTpl(props){
    const sx = useStyles(useTheme());
    const invoiceQuoteLabel = {
        invoice: 'Invoice',
        quote: 'Quote'
    }[props.type] || '';

    return (
        <Box>
           <PageHeader/>
           <Typography variant="h5"
                        sx={{pt: 0,
                             textTransform: 'uppercase',
                             borderBottom: 'solid black 1px'}}>
                Secure Online Processing
            </Typography>

            {! props.paidDate &&
               <Typography sx={{pt: 4, pb: 4}}>
                   Please confirm the following:
               </Typography>
            }

            <Box sx={{maxWidth: '350px', color: '#555', pt: 3}} >
                <RowItem label={invoiceQuoteLabel + " No."} value={props.invoiceNumber}/>
                <RowItem label="Customer ID" value={props.customerId}/>
                <RowItem label="Issue Date" value={props.issueDate}/>
                {! props.paidDate &&
                  <RowItem label="Amount Due" value={"$ " + props.dueAmount}/>
                }
                {props.paidDate &&
                   <RowItem label="Paid Date" value={props.paidDate}/>
                }
            </Box>

        </Box>
    );
}


function Save2PdfButton(){

    async function saveButtonClicked(){
        const opt = {
            margin:       0,
            filename:     'checkout.pdf',
            image:        { type: 'png' },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        const pdf = await html2pdf()
              .from(document.getElementById('checkout-page'))
              .set(opt)
              .toPdf()
              .get('pdf');
        window.open(pdf.output('bloburl'), '_blank');
    }

    return (
        <Button onClick={saveButtonClicked}>
            Save a copy
        </Button>
    );
}


/**
 *
 * Required inputs
 *  - `status` {string} returned stripe PaymentIntent Status
 */
function StripeStatusBox(props){

    function localeDateString(){
        const now = new Date();
        return now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
    }

    function confirmationCodeMsg(){
        const confirmationCode = props.confirmationCode;
        if (! confirmationCode){
            return '';
        }
        return ` Your confirmation code is ${confirmationCode}.`;
    }

    return (
        <Box>
            {(props.status === 'succeeded') &&
             <Box>
                 <Alert severity="success">
                     Thank you for your order. Your payment was successfully
                     submitted on {localeDateString()}. You will receive a separate receipt
                     via email once your order is finalized. {confirmationCodeMsg()}
                 </Alert>
                 <Save2PdfButton/>
             </Box>
            }
            {(props.status === 'processing') &&
             <Box>
                 <Alert severity="success">
                     Thank you for your order. Your payment is processing on
                     {localeDateString()}. You will receive a separate receipt via
                     email once your order is finalized. {confirmationCodeMsg()}
                 </Alert>
                 <Save2PdfButton/>
             </Box>
            }
            {(props.status === 'requires_payment_method') &&
             <Alert severity="error">
                 Your payment was not successful, please try again.
             </Alert>
            }
        </Box>
    );
}


async function fetchPaymentIntentStatus(stripePromise, clientSecret){
    const stripe = await stripePromise;
    if (!clientSecret){
        return undefined;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    return paymentIntent.status;
}


/** CheckoutPageData
 *
 * @typedef {Object} CheckoutPageData
 * @property {InvoiceData} invoiceData
 * @property {Array} errors encountered error messages, or an empty array
 * @property {string} stripeStatus stripe status
 *    ['succeeded', 'processing', 'requires_payment_method'] or empty
 *    if no status is found
 * @param clientSecret {string} stripe client secret for current
 *    invoice
 */

/**! Return data to be rendered on the checkout page
 *
 * @param {invoiceId} public invoice identifier
 * @param {customerId} public customer identifier
 * @param {Promise<Stripe>} Stripe promise
 * @return {CheckoutPageData} data to be rendered on page data
 */
async function fetchPageData(customerId, invoiceId, tid, stripePromise){
    const ret = {
        invoiceData: {},
        stripeStatus: '',
        clientSecret: undefined,
        errors: []
    };

    if (!(customerId && invoiceId)){
        return ret;
    }

    // Fetch invoice data
    try{
        const invObj = await checkoutUtils.fetchInvoiceData(customerId, invoiceId, tid);
        ret.invoiceData = invObj.data;
        if (invObj.error){
            ret.errors.push(invObj.error)
        }
    }
    catch (except){
        ret.errors.push('Could not fetch invoice data');
    }

    // Get Stripe client secret
    try{
        const clientSecret = await checkoutUtils.fetchClientSecret(
            customerId, invoiceId, tid);

        // Check if intent is already paid
        const existStatus = await fetchPaymentIntentStatus(stripePromise, clientSecret);

        if (existStatus === 'succeeded'){
            ret.stripeStatus = existStatus;
        }
        ret.clientSecret = clientSecret;
    }
    catch{
        ret.errors.push('An internal error occured attemping to initialize payment service')
    }

    return ret;
}


export default function Checkout(props){
    const invoiceDataInit = {
        number: '',
        customerId: '',
        status: '',
        total: '',
        dueAmount: '',
        issueDate: '',
        dueDate: ''
    }

    const invType = props.type || 'invoice';
    const params = useParams();

    const [error, setError] = React.useState(null);
    // card processing state
    const [processing, setProcessing] = React.useState(false);
    // invoice data
    const [invoiceData, setInvoiceData] = React.useState(invoiceDataInit);
    // page initialization state
    const [loading, setLoading] = React.useState(true);

    const [clientSecret, setClientSecret] = React.useState('');
    const [showCardPayment, setShowCardPayment] = React.useState(false);
    const [stripeStatus, setStripeStatus] = React.useState('');
    const [intentId, setIntentId] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(()=>{
        (async ()=>{
            const customerId = params.cid;
            const invoiceId = params.qid;
            const tid = invType

            const data = await fetchPageData(customerId, invoiceId, tid, stripePromise);
            const dueAmount = parseFloat(data.invoiceData.dueAmount) || 0;
            if (data.errors.length > 0){
                setError('Error: ' + data.errors.join('. '))
            }

            const showPayment = (dueAmount > 0)
                  && !data.stripeStatus
                  && (data.clientSecret !== null)
                  && data.errors.length === 0;

            setClientSecret(data.clientSecret);

            setInvoiceData(data.invoiceData);
            setShowCardPayment(showPayment);
            setStripeStatus(data.stripeStatus)
            setLoading(false);
        })();

    }, []);


    function searchAgainClicked(){
        const customerId = params.cid;
        const invoiceId = params.qid;
        const tid = invType
        return navigate('/', {
            state: {
                cid: customerId,
                qid: invoiceId,
                tid: tid
            }
        });
    }


    /** Submit payment intent and redirect
     *
     */
    async function onSubmit(stripe, cardElement){
        setProcessing(true);
        setError('');

        try{
            const resp = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement
                }
            });

            if (resp.error){
                console.log(resp.error);
                console.log(resp.error.message);
                setError(resp.error.message);
                setProcessing(false);
            }
            else if (resp.paymentIntent.status === 'succeeded'){
                setStripeStatus('succeeded');
                const intId = resp.paymentIntent.id.replace('pi_', '')
                setIntentId(intId);
                setShowCardPayment(false);
            }
        }
        catch (except){
            setError('Something went wrong processing payment.');
            setProcessing(false);
            return;
        }
    }

    return (
        <Box id="checkout-page"
             sx={{ flexGrow: 1, p: 8, maxWidth: 800, margin: '0 auto' }}>
            <Box xs={12} component={Paper} sx={{p: 8}} elevation={1}>
               { loading &&
                 <LinearProgress/>
               }

               { !loading && !invoiceData.number && error &&
                 <Box>
                     <Alert severity="error">
                         {error}
                     </Alert>
                 </Box>
               }

               { !loading && !invoiceData.number &&
                 <Box>
                     Invoice does not exist or an error was encountered.
                     Please <Button onClick={searchAgainClicked}>
                         Try again
                     </Button>, or
                     <Button href="https://www.ensoftinc.com/contact">contact</Button>
                     sales for further inquiries.
                 </Box>
               }

               { !loading && invoiceData.number &&
                 <Box>
                     <InvoiceContentTpl
                         type={invType}
                         invoiceNumber={invoiceData.number}
                         customerId={invoiceData.customerId}
                         issueDate={invoiceData.issueDate}
                         dueDate={invoiceData.dueDate}
                         status={invoiceData.status}
                         dueAmount={invoiceData.dueAmount}
                         totalAmount={invoiceData.total}
                         paidDate={invoiceData.paidDate}
                     />
                     { error &&
                      <Alert severity="error">
                          {error}
                      </Alert>
                     }
                     { showCardPayment &&
                       <FormControl component="fieldset" sx={{width: '100%'}}>
                           <Box sx={{pt: 5}}>
                               <FormLabel>Payment Information</FormLabel>
                               <Box sx={{pb: 4, pt: 4}}>
                                   <Elements stripe={stripePromise} options={{}}>
                                       <StripePaymentInput
                                           processing={processing}
                                           clientSecret={clientSecret}
                                           onSubmit={onSubmit} />
                                   </Elements>
                               </Box>
                               <Typography
                                   variant="p" sx={{
                                       paddingTop: 0,
                                       fontStyle: 'italic',
                                       fontSize: '12px',
                                       color: '#8a8a8a'
                                   }}>
                                   Ensoft, Inc does not store credit card information on file.
                               </Typography>
                           </Box>
                       </FormControl>
                     }
                 </Box>
               }
               { !loading && stripeStatus &&
                 <StripeStatusBox status={stripeStatus} confirmationCode={intentId} />
               }
            </Box>
        </Box>
    );
}
