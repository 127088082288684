import config from '../config/config.js';
import axios from 'axios';


/** InvoiceData
 *
 * @typedef {Object} InvoiceData
 * @property {string} number public invoice identifier
 * @property {string} customerId public customer identifier
 * @property {string} status public invoice staus, ['Paid', 'Pending', 'Void']
 * @property {float} total total invoice amount
 * @property {float} dueAmount amount that is due, <= total
 * @property {string} issueDate date invoice was issued in format YYYY-mm-dd
 * @property {string} dueDate date invoice is due, in format YYYY-mm-dd
 */

/** InvoiceDataResponse
 *
 * @typedef {Object} InvoiceDataResponse
 * @property {InvoiceData} data invoice data
 * @property {string} error error, if any
 */


function toLocaleDateString(datestr){
    if (!datestr){
        return '';
    }

    const d = new Date(datestr);
    return d.toLocaleDateString();
}


function toLocaleDateTimeString(datestr){
    if (!datestr){
        return '';
    }

    const d = new Date(datestr);
    return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
}


/**! Fetch invoice data from api
 *
 * @param {string} invoiceNumber invoice or quote number
 * @param {string} customerId customer identifier
 * @param {('invoice', 'quote')} type invoice/quote selector
 * @return {InvoiceDataResponse} invoice data
 */
async function fetchInvoiceData(customerId, invoiceNumber, type){
    const endPoint = config.apiEndpoint + '/payments/q';
    const opts = {
        params: {
            cid: customerId,
            qid: invoiceNumber,
            tid: type
        }};
    const resp = await axios.get(endPoint, opts);
    const data = resp.data;
    data.data.issueDate = toLocaleDateString(data.data.issueDate);
    data.data.paidDate = toLocaleDateTimeString(data.data.paidDate);

    return data;
}


async function fetchClientSecret(customerId, invoiceNumber, tid){
    const endPoint = config.apiEndpoint + '/payments/intent';
    const body = {
        cid: customerId,
        qid: invoiceNumber,
        tid: tid
    };
    const resp = await axios.post(endPoint, body);
    return resp.data.clientSecret;
}



export {
    fetchInvoiceData,
    fetchClientSecret
};
