import React from 'react';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from '../Navbar.module.scss';



const useStyles = (theme) => ({
    root: {
        backgroundColor: '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    size: {
        padding: '20px',
        paddingTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        margin: theme.spacing(4, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
});


export default function InvoiceSearch(props){
    const locParams = useLocation();
    const sx = useStyles(useTheme());
    const [query, setQuery] = React.useState({customerId: '' , invoiceId: '', type: 'invoice'});

    const [invoiceQuoteLabel, setInvoiceQuoteLabel] = React.useState('Invoice');

    const onFieldChange = (property, event)=>{
        setQuery({ ...query, [property]: event.target.value })
    }

    let navigate = useNavigate();

    const onSearch = (event)=>{
        event.preventDefault();
        // Ignore periods in customer ids
        const customerId = query.customerId.replace('.', '');
        const invoiceId = query.invoiceId;
        const type = query.type
        navigate(`/checkout/${type}/${customerId}/${invoiceId}`);
    };

    const formIsValid = ()=>{
       return (query.invoiceId.length !== 0) && (query.customerId.length !== 0);
    }

    const dropDownChanged = (event)=>{
        const value = event.target.value;
        const label = value.charAt(0).toUpperCase() + value.slice(1);
        setInvoiceQuoteLabel(label);
        setQuery({...query, type: value});
    }

    React.useEffect(()=>{
        const state = locParams.state;
        if (!state){
            return;
        }
        setQuery({
            customerId: state.cid || '',
            invoiceId: state.qid || '',
            type: state.tid || ''
        });
    }, []);

    return (
        <Grid container component="main" sx={sx.root}>
            <CssBaseline />

            <Grid item xs={12}>
               <div className={styles.bootstrap}>
                  <div className="container">
                     <div className="page-title">
                        <h1> Guest Checkout </h1>
                        <div className="border-bottom-50"></div>
                     </div>
                  </div>
               </div>
            </Grid>

            <Grid sx={sx.size} item xs={12} sm={8} md={5}
                  elevation={1}>
                <div sx={sx.paper}>

                    <Typography component="h1" variant="h5" sx={{pt: 5, pb: 5}}>
                        Invoice/Quote Information
                        <Tooltip title="Please enter the fields below as found in the top right corner of the PDF document, received from the Ensoft Sales team.">
                           <InfoIcon/>
                        </Tooltip>
                    </Typography>

                    <FormControl sx={sx.form} size="small">
                        <InputLabel id="invoice-quote-label">Invoice or Quote</InputLabel>
                        <Select
                            label="Invoice or Quote"
                            labelId="invoice-quote-label"
                            value={query.type}
                            onChange={dropDownChanged}
                        >
                            <MenuItem value={'invoice'}>Invoice</MenuItem>
                            <MenuItem value={'quote'}>Quote</MenuItem>
                        </Select>

                        <TextField
                            onChange={(event)=>onFieldChange("customerId", event)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Customer ID"
                            id="CustomerId"
                            name="customerId"
                            required
                            value={query.customerId}
                            autoFocus
                        />
                        <TextField
                            onChange={(event)=>onFieldChange("invoiceId", event)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="invoiceId"
                            label={invoiceQuoteLabel + " Number"}
                            id="invoiceId"
                            value={query.invoiceId}
                            required
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={sx.submit}
                            onClick = {onSearch}
                            disabled={!formIsValid()}
                        >
                            Search
                        </Button>

                        <Box mt={5}>
                        </Box>
                    </FormControl>
                </div>
            </Grid>
        </Grid>
    );
}
