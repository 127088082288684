import styles from './Navbar.module.scss';


export const Navbar = (props) => {
    const home = 'https://ensoftinc.com';

    return (
        <div className={styles.bootstrap}>
            <nav className="navbar navbar-inverse navbar-fixed-top marginBottom-0" role="navigation">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button"
                                className="navbar-toggle"
                                data-toggle="collapse"
                                data-target="#navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>

                        <div className="nav-logo">
                            <div className="nav-logo-borderpulse"> </div>
                            <a href={home}
                               title="Ensoft Inc">
                            <img src="https://ensoftinc.com/img/logo.png"
                                 alt="Ensoft Logo"/></a>
                        </div>

                        <a className="nav-text" href={home} title="Ensoft Inc">
                            <h3 className="navbar-text"> ENSOFT Inc </h3>
                        </a>
                    </div>

                    <div className="collapse navbar-collapse" id="navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right">
                            <li className="dropdown">
                                <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
                                ABOUT <b className="caret"></b></a>
                                <ul className="dropdown-menu">
                                    <li><a href={home + "/company"}> Our Company </a></li>
                                    <li><a href={home + "/contact"}> Contact </a></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
                                PRODUCTS <b className="caret"></b> </a>
                                <ul className="dropdown-menu">
                                    <li><a href={ home + "/products"}>All Products </a></li>
                                    <li><a href={ home + "/downloads"}>Downloads</a></li>
                                    <li><a href={ home + "/order_form"}>Order Form</a></li>
                                    <li><a href={ home + "/faq"}>FAQ</a></li>
                                    <li><a href={ home + "/copyright"}>Copyright & Disclaimer</a></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
                                SERVICES <b className="caret"></b> </a>
                                <ul className="dropdown-menu">
                                    <li><a href={ home + "/services"}> LCR&amp;A Consulting </a></li>
                                    <li><a href={ home + "/field_services"}> Testing &amp; Instrumentation </a></li>
                                    <li><a href={ home + "/remote_monitoring"}>Remote Monitoring</a></li>
                                </ul>
                            </li>
                            <li><a href={home + "/seminars"}>SEMINARS</a></li>
                            <li><a href={home + "/news"}>NEWS</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
